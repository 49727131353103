
import { LatLngTuple } from "leaflet"
import { createContext, ReactNode, useContext, useState } from "react"

const GeoContext = createContext<ReturnType<typeof useGeoState> | null>(null)

interface iGeo {
    lastUpdated: number,
    state: "ERROR" | "SUCCESS" | "UNKNOWN" | "PENDING",
    watcherId: number | undefined,
    position: LatLngTuple | undefined
}

const GeoProvider = ({children}:{children: ReactNode}) => {
  const geo = useGeoState()
  return <GeoContext.Provider value={geo}>{children}</GeoContext.Provider>
}

const initialState : iGeo = {
    lastUpdated: 0,
    state: "UNKNOWN",
    watcherId: undefined,
    position: undefined
}

const useGeoState = () => {
    const [geo, setGeo] = useState<iGeo>(initialState)
    let watcherId : number | undefined = undefined;
    let positionCb : (pos:LatLngTuple)=>void = () => {}

    const success = (position:any) => {
        const latlng : LatLngTuple = [position?.coords?.latitude, position?.coords?.longitude]

        if(latlng[0] === 0 || latlng[1] === 0){
            setGeo({
                lastUpdated: position?.timestamp,
                state: "PENDING",
                watcherId,
                position: latlng
            })
            return
        }

        setGeo({
            lastUpdated: position?.timestamp,
            state: "SUCCESS",
            watcherId,
            position: latlng
        })
        if(positionCb){
            positionCb(latlng)
        }
    }

    const error = (err?:any) => {
        setGeo({
            lastUpdated: Date.now(),
            state: "ERROR",
            watcherId,
            position: undefined
        })
    }

    const startGeo = (positionCallback?:(pos:LatLngTuple)=>void) => {
        if(positionCallback){
            positionCb = positionCallback
        }
        if(geo.watcherId !== undefined) return;
        watcherId = navigator.geolocation.watchPosition(success,error)
    }

    return {geo, setGeo, startGeo}

}

const useGeo = () => {
  const geo =  useContext(GeoContext)
  if(geo === null) throw Error("use <GeoProvider></GeoProvider> on the top level to use the Geo")
  return geo
}


export {GeoProvider,useGeo}

