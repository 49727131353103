import { Link, Path, useNavigate } from 'react-router-dom';
import './App.css';
import Logo from './components/Logo';
import {reset as resetInventory} from './utils/inventory'
import {reset as resetPhotos} from './utils/photoinventory'

const FirstPage = () => {
    
    const navigate = useNavigate();
    
    const resetStorage = (redirectTo:string) => {
        sessionStorage.clear()
        //resetInventory({type:"reset"})
        //resetPhotos({type:"reset"})
        resetInventory()
        resetPhotos()
        navigate(redirectTo)
    }


    return <div className="FirstPage">
        <div><Logo></Logo></div>
        <div className='content'>
            <div><Link to={`/powstanie`}>Gra Śladami powstania w Getcie Warszawskim</Link></div>
            <div onClick={()=>resetStorage('/powstanie')}>Nowa gra</div>
            <div className='divider'></div>
            <div><Link to={`/przewodnik`}>Audioprzewodnik</Link></div>
            <div className='divider'></div>
            <div><a href='https://1943.pl' target={"_blank"}>Strona www muzeum</a></div>
        </div>
        <div>polityka prywatnosci</div>
    </div>
}

export {FirstPage}