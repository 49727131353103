import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import ChatApp from './ChatApp';
import AudioGuideApp from './GuideApp';

import {
  createHashRouter,
  RouterProvider,
  Route,
  Link,
  createBrowserRouter,
} from "react-router-dom";


import { FullscreenProvider } from './components/FullscreenPicture';
import Logo from './components/Logo';
import { FirstPage } from './FirstPage';
import { PhotoInventoryProvider } from './utils/photoinventory';
import { ConfigProvider } from './utils/useconfig';
import { GeoProvider } from './utils/usegeo';


const router = createHashRouter([
  {path:'/', element: <FirstPage/> },
  {path:'/powstanie', element: <ChatApp/>},
  {path:'/przewodnik', element: <AudioGuideApp/>},
])

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

//<RouterProvider router={router}></RouterProvider>
root.render(
  <React.StrictMode>
    <GeoProvider>
    <FullscreenProvider>
    <ConfigProvider>
    <RouterProvider router={router}></RouterProvider>
    </ConfigProvider>
    </FullscreenProvider>
    </GeoProvider>
  </React.StrictMode>
);



//  <AudioGuideApp />
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
