import { iGuidePoint } from "../utils/guideDataAccess"
import { useIsMounted } from "../utils/utilhooks"
import { useFullscreen } from "./FullscreenPicture"


const GuidePointCover = ({name,photos}:{name:string,photos:string | string[]}) => {
    const [_,setFullscreen] = useFullscreen()
    
    typeof photos == "string" && (photos = [photos])

    return <div className="GuidePointCover">
        <h3>{name}</h3>
        <div className="PhotoList">
        {photos.map(photo => <img src={photo} key={photo} onClick={()=>setFullscreen({src:photo, align:"center"})} /> )}
        </div>
    </div>
}

const GuideAudioPlayer = ({mp3}:{mp3?:string}) => {
    return <div className="GuideAudioPlayer">
        <audio controls src={mp3}></audio>
    </div>
}

const GuidePanel = ({point,visible,clickOutsideHandler}:{
    point:iGuidePoint,
    visible:boolean,
    clickOutsideHandler?:()=>void
}) => {

    const name = point.name
    let isVisible = visible

    let panelRef : any;
    const outsideClickHandler = (clickedEl:any) => {
        if(panelRef !== clickedEl.target) return;
        clickOutsideHandler && clickOutsideHandler()
    }

    //let picture = Array.isArray(point.photo) ? point.photo[0] : point.photo 

    return isVisible ? <div className="GuidePanel" ref={(el:any)=>panelRef = el} onClick={outsideClickHandler}>

        <GuidePointCover name={point.name ?? point.id} photos={point.photo ?? []}></GuidePointCover>
        <GuideAudioPlayer mp3={point.mp3}></GuideAudioPlayer>

    </div> : null;
} 

export {GuidePanel}

