import React, { createContext, ReactNode, useCallback, useContext, useEffect, useReducer, useRef, useState } from 'react';

import './App.css';
import { BottomBar } from './components/BottomBar';
import {iPoint, Map} from './components/Map'
import { ChatPanel } from './components/ChatPanel';
import { bounds, divIcon, latLngBounds, LatLngTuple, Map as MapClass } from 'leaflet';

import { getClosestPoints } from './utils/latlngutils';
import { useInitOnce } from './utils/utilhooks';

import avatar1 from './assets/avatar1.png'
import { getGameLocalisations, getGameItems, iGamePoint, iGameItem, iConfig, getConfig, findItemFromId } from './utils/dialogueDataAccess';
import {  InventoryProvider, set as setInventory, useInventory } from './utils/inventory';

import GeneralMapApp from './components/GeneralMapApp';
import cameraicon from './assets/cameraicon.svg'

import { PhotoInventoryProvider, usePhotoInventory } from './utils/photoinventory';
import { useMap } from 'react-leaflet';
import { useFullscreen } from './components/FullscreenPicture';
import { ConfigProvider, useConfig } from './utils/useconfig';
import { useGeo } from './utils/usegeo';
import { GeolocationNeededModal, OneButtonModal } from './components/Modal';
import { GpsStatusBar } from './components/GpsStatusBar';
import { useMapBounds } from './utils/usemapbounds';
import { readObj, saveObj } from './utils/saveobj';


const photoIcon = divIcon({className:"photoIcon",html:`<img src="${cameraicon}"/>`})

const gameName = "powstanie"
const gameLang = "PL"

//const center : LatLngTuple = [52.260763772822294, 20.90190523906131]
const center : LatLngTuple = [52.25184771386281, 20.9960406459868]

function MapPhotoPopup({point}:{point:iGamePoint}){
  const map = useMap()
  const [isPhotoCollected,dispatch] = usePhotoInventory(store => store.find(item => item.picture == point.picture))  
  const [photoOutroShowed,setPhotoOutroShowed] = useState(false)
  const [collectedPhotosCount] = usePhotoInventory(store => store.length)
  const [_,setFullscreen] = useFullscreen()

  const collectPhotoHandler = () => {
    map.closePopup()
    dispatch({type:"take",item:{picture:point.picture ?? "",description:point.description}})
    if(collectedPhotosCount == 21 && !photoOutroShowed){
      setPhotoOutroShowed(true)
      setFullscreen("games/powstanie/anim/outro.mp4")
    } else {
      setFullscreen("games/powstanie/anim/banka_wpada.mp4")
    }
  }
  return isPhotoCollected !== undefined
  ? <div className='MapPopup'><img src={point.picture}></img><div><p>Zebrane!</p></div></div>
  : <div className='MapPopup' onClick={collectPhotoHandler}><img src={point.picture}></img><div><p>Nowe</p><p>zdjęcie</p></div></div>
}

function MapDialoguePopup({point,onClick}:{point:iGamePoint,onClick:()=>void}){
  const map = useMap()
  const clickHandler = () => {
    onClick();
    map.closePopup()
  }
  return  <div className='MapPopup' onClick={clickHandler}><img src={point.picture}></img><div><p>Masz</p><p>wiadomość</p></div></div>
}



function ChatAppWithInventories(){
  return <InventoryProvider>
    <PhotoInventoryProvider>
      <ChatApp/>
    </PhotoInventoryProvider>
    </InventoryProvider>

}


function ChatApp() {
  const [userPos, setUserPos] = useState(center)
  const [choosenPoint,setChoosenPoint] = useState("")
  const [closeToPoint, setCloseToPoint] = useState<ReturnType<typeof getClosestPoints>[number] | null>(null)

  const [points, setPoints] = useState<iPoint[]>([])
  const [config, setConfig] = useConfig()

  //const [avatar, setAvatar] = useState<string | null>(null)
  const [initialData,setInitialData] = useInitialData()

  const [visitedPoints, setVisitedPoints] = useState(new Set())

  const [outroShowed,setOutroShowed] = useState(false)


  const [_,setFullscreen] = useFullscreen()

  const [gameBounds, setGameBounds] = useState<L.LatLngBounds | null>(null)

  const mapRef = useRef<MapClass | null>(null)
  let chatPanels : React.ReactNode = []
  let {geo} = useGeo()

  const mapBounds = useMapBounds(mapRef)

  //console.log('ChatApp state: points ',points.length,'gameBounds',gameBounds,'config',config)
  
  const openClosestPoint = (pointId:string) => setPoints(points => points.map(point => ({...point,isActive:(point.id==pointId)}))) 

  const onKeyPress = (ev:any) => {
    const key = ev.key;
    const delta = 10
    if(mapRef.current){
      const posInPixels = mapRef.current.latLngToContainerPoint(userPos)
      switch(key){
        case 'w': posInPixels.y -= delta; break;
        case 's': posInPixels.y += delta; break;
        case 'a': posInPixels.x -= delta; break;
        case 'd': posInPixels.x += delta; break;
      }
      const latlng = mapRef.current.containerPointToLatLng(posInPixels)
      const latlngtuple : LatLngTuple = [latlng.lat,latlng.lng]
      setUserPos(latlngtuple)
      goToPos(latlngtuple)
      //console.log(posInPixels,userPos)
    }
  }

  /* GPS WYLACZONY DO TESTOW
  useEffect(()=>{
    console.log('Geo changed',geo)
    if(geo.state=="SUCCESS" && geo.position){
      setUserPos(geo.position)
      goToPos(geo.position)
    }
  },[geo])
  */

  useEffect(()=>{
    document.body.addEventListener('keypress',onKeyPress)
    return ()=>document.body.removeEventListener('keypress',onKeyPress)
  })

  const hideChat = ()=> {
    //tutaj odpalamy outro jak mamy 8 odwiedzonych miejsc
    if(visitedPoints.size === 8 && !outroShowed && config?.outro_movie){
      setFullscreen({src:config.outro_movie,onClose:()=>setOutroShowed(true)})
    }

    setChoosenPoint("")
    setCloseToPoint(null)
  }

  const goToPos = (latlngtuple : LatLngTuple) => {
    
    if(!points.length) return;

    const closestPoint = getClosestPoints(latlngtuple,points)[0]
    console.log(latlngtuple,closestPoint)
    //console.log(latlngtuple)
    if(closestPoint.distance < 30 && closestPoint.id!=closeToPoint?.id){
      openClosestPoint(closestPoint.id)
      setCloseToPoint(closestPoint)
    } else {
      setCloseToPoint(null)
    }

    
    if(!mapBounds || !gameBounds) return;

    if(gameBounds.contains(latlngtuple) && !mapBounds.contains(latlngtuple)){
      mapRef.current?.panTo(latlngtuple)
    }

  }

  const mapClickHandler = (ev:any) => {
    const latlngtuple = [ev.latlng.lat, ev.latlng.lng] as LatLngTuple
    //if(geo.state !== "ERROR") return;
    setUserPos(ev.latlng)
    goToPos(latlngtuple)
  }

  const visitPoint = (pointid:string) => {
    setChoosenPoint(pointid)
    setVisitedPoints(set=>set.add(pointid))
  }

  const centerOnUserClickHandler = () => {
    if(mapRef.current === null)return;
   mapRef.current.panTo(userPos)
  }

  const initialized = useInitOnce(
    async () => {

      console.log('ChatApp init once')
      const points : iGamePoint[] = (await getGameLocalisations(gameName,gameLang)) ?? []

      setPoints(points.map(p=>
        p.type == "photo" 
        ? {...p, icon: photoIcon, Popup: <MapPhotoPopup point={p} />} 
        : {...p, Popup: <MapDialoguePopup point={p} onClick={()=>visitPoint(p.id)} /> }
      ))

      const items = (await getGameItems(gameName,gameLang)) ?? []
      const config = (await getConfig(gameName,gameLang)) ?? []

      typeof config?.first_item == "string" && (config.first_item = {...findItemFromId(config.first_item,items)})
      config.loaded = true;

      setGameBounds(latLngBounds(points.map(p => p.localisation)))

      setConfig(config)
      
      //setInventory({type:"init",item:items[0]})
    }
  )

  chatPanels = (points as iGamePoint[]).filter(p=>p.type!=='photo').map(point=>
    <ChatPanel meavatar={initialData.avatar} point={point} key={point.id} visible={choosenPoint==point.id} clickOutsideHandler={hideChat}></ChatPanel>
    )

  //console.log('ChatApp render')
  return  (
      <GeneralMapApp points={points} center={center} userPos={userPos} mapClickHandler={mapClickHandler} mapRefCallback={(ref:MapClass)=>mapRef.current = ref}>
        <React.Fragment>
        {config?.loaded ? <IntroModalSequence initialData={initialData} setInitialData={setInitialData}/> : null} 
        {chatPanels}
        </React.Fragment>
        <GpsStatusBar bounds={gameBounds} mapbounds={mapBounds} map={mapRef.current}></GpsStatusBar>
        <BottomBar avatar={initialData.avatar} onAvatarClick={centerOnUserClickHandler}></BottomBar>
      </GeneralMapApp>
  ) ;
}


function IntroModalSequence({initialData, setInitialData}:{
  initialData : ReturnType<typeof useInitialData>[0],
  setInitialData: ReturnType<typeof useInitialData>[1]
}){

  const [inventoryIsEmpty, setInventory] = useInventory(store => store.length == 0)
  const [photoInventoryIsEmpty] = usePhotoInventory(store => store.length == 0)
  const isLaunchedFromHomescreen = launchedFromHomeScreen()
  const [skipPhotoIntro, setSkipPhotoIntro] = useState(false)
  const [_,setFullscreen] = useFullscreen()
  const [config] = useConfig()

  const {geo} = useGeo()

  
  try {
    if(initialData.firstVisit && config?.intro_movie) {
      setInitialData({type:"FIRST_VISIT"})
      setFullscreen({src:config.intro_movie})
      return null;
    } 
  } catch(err){
    console.log(err)
  }
  
  if(!initialData.avatar && config?.avatars?.length) return <AvatarChoiceModal avatars={config.avatars} onChoice={(avatar:string)=>setInitialData({type:"AVATAR",avatar})} />

  if(inventoryIsEmpty) return <InventoryInfoModal onClick={()=>setInventory({type:"init",item:config?.first_item})} />
  if(photoInventoryIsEmpty && !skipPhotoIntro)return <PhotoInventoryInfoModal onClick={()=>setSkipPhotoIntro(true)} />


  if(geo.state === 'UNKNOWN' || geo.watcherId === undefined)return <GeolocationNeededModal />

  //if(!isLaunchedFromHomescreen)return <InstallAppModal />

  return null;
}

function AvatarChoiceModal({avatars,onChoice}:{avatars:string[],onChoice:(avatar:string)=>void}){
  return <OneButtonModal title='Wybierz avatar'>
    {
      avatars.map(avatar => <div key={avatar} className='avatarChoiceItem' onClick={()=>onChoice(avatar)}><img src={avatar}></img></div>)
    }
  </OneButtonModal>
}

function InventoryInfoModal({onClick}:{onClick:()=>void}){
  return <OneButtonModal title ="Pierwsze zadanie" buttonTitle='Dalej' buttonClick={onClick}>
    Twoje pierwsze zadanie to zanieść kubek do najblizszego punktu.
  </OneButtonModal>
}

function PhotoInventoryInfoModal({onClick}:{onClick:()=>void}){
  return <OneButtonModal title ="Zbieraj zdjęcia" buttonTitle='Dalej' buttonClick={onClick}>
    Po drodze natrafisz na punkty oznaczone <img src={cameraicon} style={{height:"20px",filter:"invert(1)"}} ></img>, to zdjęcia, które mozesz zbierać
  </OneButtonModal>
}


interface iInitialDataAction {
  type: "AVATAR" | "FIRST_VISIT",
  avatar?:string
}

interface iInitialState {
  avatar?:string,
  firstVisit?:boolean
}

function useInitialData(){
  const saveKey = 'gameInitialState'
  const savedState = readObj(saveKey)
  //todo: readObj odpala przy kazdym renderze!!
  const initialState : iInitialState = savedState!==undefined ? savedState as iInitialState  : {
    avatar:"",
    firstVisit:true,
  }

  const reducer = useCallback((state: iInitialState,action:iInitialDataAction):iInitialState =>{
    switch(action.type){
      case "AVATAR": return {...state,avatar:action.avatar ?? ""}
      case "FIRST_VISIT": return {...state,firstVisit:false}
    }
  },[])

  const savingReducer = useCallback((state: iInitialState,action:iInitialDataAction):iInitialState =>{
    const newstate = reducer(state,action)
    saveObj(saveKey,newstate)
    return newstate;
  },[])


  return useReducer(savingReducer,initialState)
}

function launchedFromHomeScreen(){
  return false;
}



export default ChatAppWithInventories;


