import { useGeo } from "../utils/usegeo";
import { divIcon, latLngBounds, LatLngTuple, Map as MapClass } from 'leaflet';

function GpsStatusBar({bounds,mapbounds,map}:{bounds?:L.LatLngBounds | null, mapbounds?:L.LatLngBounds | null, map: MapClass | null}){
    const {geo} = useGeo()
    //const map = useMap()
    if(geo.state=="ERROR")return <div className='YouHaveAMessage'>Błąd: brak dostępu do danch lokalizacji <a href="">pomoc</a></div>
    if(geo.state=="SUCCESS" && bounds && mapbounds && map && !mapbounds.intersects(bounds))return <div className='YouHaveAMessage'>Jesteś poza terenem punktów. <a onClick={()=>map && map.panTo(bounds.getCenter())}>Przesuń mapę</a></div>
    //console.log('GpsStatusBar doesnt render', geo.state, bounds, mapbounds,  bounds && mapbounds?.intersects(bounds))
    return null;
  }

  export {GpsStatusBar}