import { ReactNode, ReducerAction, useEffect, useRef, useState } from "react"
import { iGameItem } from "../utils/dialogueDataAccess"
import MGW_PL_White  from '../MGW_PL_White.png'
import { useClickToFullscreen, useFullscreen } from "./FullscreenPicture"
import { iPhotoItem, usePhotoInventory } from "../utils/photoinventory"
import { useInventory } from "../utils/inventory"
import { Link } from "react-router-dom"


const BottomBarCircle = ({children,empty,onClick} : {children?: JSX.Element, empty?:boolean, onClick?:()=>void}) => {

    return <div className="BottomBarItem circle" {...{onClick}} style={{opacity:empty===true?0.2:1}}>{children ?? null}</div>
}

const BottomBarSquare = ({children,empty,onClick} : {children?: JSX.Element, empty?:boolean, onClick?:()=>void}) => {

    return <div className="BottomBarItem" {...{onClick}} style={{opacity:empty===true?0.2:1}}>{children ?? null}</div>
}


const InventoryPanel = ({headerText, hideFunc, children}:{headerText:string, hideFunc:()=>void, children:ReactNode}) => {
    const contentRef = useRef(null)
    const closeClickHandler = (ev:any) => {
        ev.target == contentRef.current && hideFunc()
    }

    return  <div className="InventoryPanel" onClick={closeClickHandler} ref={contentRef}>
        <div className="InventoryContent" >
            <h2>{headerText}</h2>
            <div className="InventoryItems">
            {children}
            </div>
        </div>
    </div>
}

const InventoryItemsPanel = ({hideFunc}:{hideFunc:()=>void}) => {
    const [items] = useInventory(store=>store)
    const itemClickHandler = useClickToFullscreen()

    return <InventoryPanel headerText="TWOJE PRZEDMIOTY" hideFunc={hideFunc}>
        {items.filter(i=>!!i.picture).map(item => <div key={item.id} className="InventoryItem circle" onClick={(ev)=>itemClickHandler(item,ev)}><img src={item.picture} className={item.isCompleted ? "completed" : undefined} /></div>)}
    </InventoryPanel>
}

const InventoryPhotosPanel = ({hideFunc}:{hideFunc:()=>void}) => {
    const [photos] = usePhotoInventory(store=>store)
    const itemClickHandler = useClickToFullscreen()
    const photosToShow = photos.filter(i=>!!i.picture)

    return <InventoryPanel headerText="ZDJĘCIA W BAŃCE" hideFunc={hideFunc}>
        {photosToShow.map(item => <div key={item.picture} className="InventoryItem" onClick={(ev)=>itemClickHandler(item,ev)}><img src={item.picture}/></div>)}
        {!photosToShow.length ? <div className="description">W tym miejscu pojawią się zdjęcia, które zebrałeś do bańki odwiedzając punkty oznaczone [ikona]</div> : null}
    </InventoryPanel>
}

const BottomBar = ({avatar,onAvatarClick}:{
    avatar?:string,
    onAvatarClick?:()=>void
}) => {

    const [panelState, setPanelState] = useState<"items" | "photos" | null>(null)

    const [lastItem] = useInventory(store => store[store.length-1])
    const [lastPhoto] = usePhotoInventory(store => store[store.length-1])

    const openItemsInventory = () => setPanelState("items")
    const openPhotosInventory = () => setPanelState("photos")
    const hidePanel = () => setPanelState(null)

    return <div>
        {
            panelState === "items" ? <InventoryItemsPanel hideFunc={hidePanel} />
            :panelState === "photos" ? <InventoryPhotosPanel hideFunc={hidePanel} />
            :null
        }
        <div className="BottomBar">
        {
            !!avatar ? 
            <BottomBarCircle onClick={()=>onAvatarClick && onAvatarClick()} key="avatar" empty={false}>
                <img src={avatar}></img>
            </BottomBarCircle>
            : null
        }
        <div className="items">
            <BottomBarCircle onClick={openItemsInventory} empty={!lastItem}>
                {lastItem ? <img className={lastItem?.isCompleted == true ? 'completed':''} src={lastItem.picture}></img> : undefined}
            </BottomBarCircle>
            <BottomBarSquare onClick={openPhotosInventory}>
                {lastPhoto ? <img src={lastPhoto.picture}></img> : undefined}
            </BottomBarSquare>
        </div>

        <Link to="/"><img className="logo" src={MGW_PL_White}></img></Link>

    </div>
    </div>
}

export {BottomBar}




