import { createContext, ReactNode, useContext, useState } from "react"
import { iConfig } from "./dialogueDataAccess"


const ConfigContext = createContext<ReturnType<typeof useConfigState> | null>(null)

const ConfigProvider = ({children}:{children: ReactNode}) => {
  const config = useConfigState()
  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
}



function useConfigState(): [iConfig | null, (config:iConfig | null)=>void] {
  const [config,setConfig] = useState<iConfig | null>(null)

  function wrapSetConfig(config:iConfig | null){
      console.log('SET CONFIG ',config)
      setConfig(config)
  }

  return [config, wrapSetConfig]
}

const useConfig = () => {
  const config =  useContext(ConfigContext)
  if(config === null) throw Error("use <ConfigProvider></ConfigProvider> on the top level to use the Config")
  return config
}


export {ConfigProvider,useConfig}